body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* 0C57FB • EBF0F4 • FFFFFF • 1D2B3B */

.navbar{
  background-color: #1D2B3B;
}

h6 {
  font-weight: bold;
}

.navbar-brand,.nav-link{
  color: #fff !important;
}

img{
  max-width: 100%;
}

.searchbar{
  background-color: transparent !important;
  border: 0;
  text-align: right;
  color: #fff !important;
  border-radius: 0;
  outline: none !important;
}

.form-control{  
  transition: none !important;
}

.read{
  color: #1D2B3B;
}

.thumbnail{
  background-color: #EBF0F4;
  margin: 10px auto;
  border: 2px solid #000;
  width: 80%;
  padding: 10px;
  border: 1px solid #EAEDF3;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
  border-radius: 4px;
}

p>strong {
  color: #1D2B3B;
}

.desc {
  width : 90%;
  margin-top: 15px;
}

.company-logo{
  margin-top: 10px;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  object-fit: contain;
  margin-left: 25px;
}

strong>a{
  color: #0C57FB;
  font-size: 14px;
}

.strong{
  display: flex;
  justify-content: space-between;
}

.apply{
  margin-right: 25px;
  color: #fff;
  background-color: #1D2B3B;
  padding: 5px 10px;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.04);
}

.apply:hover{
  color: #fff;
}

.hide {
  display: none;
}

@media only screen and (max-width: 768px){
  .thumbnail, .thumbnail > .row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .company-logo {
    margin-left: 10px;
  }
  .apply {
    margin-right: 5px;
  }
}

@media only screen and (max-width: 500px){
  .thumbnail, .thumbnail > .row {
    width: 100%;
    display: block !important;
    /* justify-content: space-between;
    align-items: center; */
  }
  .company-logo {
    margin: 5px auto 10px 10px;
    
  }
  .strong {
    display: block;
  }
  .apply {
    margin-top: 0 auto 15px 10px;

  }
  .hide {
    display: block;
  }
  .read_ml {
    font-weight: bold;
  }
}
